import React, {Suspense} from 'react'
import {useSelector} from 'react-redux'
import {useAuth} from './hook/useAuth'
import LoaderDots from './components/Elements/LoaderDots'

// import 'flatpickr/dist/flatpickr.min.css' // todo попробовать стили календаря всунуть в конкретные компоненты и посмотреть, увеличится ли от этого размер билда
// import './css/flatpickr.css'
//
// import 'react-image-gallery/styles/css/image-gallery.css'
// import './css/image-gallery.scss'
//
// import './css/Around/scss/theme.scss'


const AdminRouting = React.lazy(() => import('./AdminRouting'))
const CustomerRouting = React.lazy(() => import('./CustomerRouting'))
const Maintain = React.lazy(() => import('./components/Plugs/Maintain'))


const App = () => {

    const isCustomerMode = useSelector(state => state.customerMode.isCustomerMode)
    const isMaintain = useSelector(state => state.maintain.value)
    const {isLoading, token, isAuthorized} = useAuth()


    if (isLoading) return <LoaderDots/>

    if (isMaintain) {
        return (
            <Suspense fallback={<LoaderDots/>}>
                <Maintain/>
            </Suspense>
        )
    }

    return (
        <Suspense fallback={<LoaderDots/>}>
            {
                (!token || isCustomerMode) ?
                    <CustomerRouting/> :
                    (isAuthorized ? <AdminRouting/> : null)
                    // <AdminRouting/>
            }
        </Suspense>
    )

}

export default React.memo(App)
